<template>
  <div class="border-top-info border-3 border-table-radius px-0">
    <filter-slot
      :filter="[]"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="$refs['refRolesCommissionsTable'].refresh()"
    >
      <template #table>
        <b-table
          ref="refRolesCommissionsTable"
          no-border-collapse
          class="position-relative"
          :fields="fields"
          show-empty
          no-provider-filtering
          sticky-header="50vh"
          primary-key="id"
          responsive="sm"
          :items="getRolesCommissionsByProgram"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :current-page="paginate.currentPage"
          :per-page="paginate.perPage"
          :busy.sync="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template #cell(value)="data">
            <validation-observer ref="form">
              <validation-provider
                v-slot="{errors}"
                name="percentage"
                rules="required|validate-amount"
              >
                <div class="d-flex">
                  <money
                    v-model="data.item.value"
                    v-bind="{
                      decimal: '.',
                      thousand: ',',
                      precision: 2,
                      min: 0.0,
                      prefix: data.item.type_comission_number === 1? ' $' : ' ',
                      suffix: data.item.type_comission_number === 2? ' %' : ' ',
                      maxlength: 10,
                    }"
                    class="form-control w-25 input-form"
                    :disabled="selectedRow.id !== data.item.id"
                  />
                  <feather-icon
                    v-if="data.item.count >= 1"
                    v-b-tooltip.hover.top="'Request change is pending'"
                    icon="InfoIcon"
                    size="20"
                    style="margin-top: 0.5rem"
                    class="cursor-pointer text-danger ml-1"
                  />
                  <div v-else>
                    <feather-icon
                      v-if="selectedRow.id !== data.item.id"
                      icon="Edit2Icon"
                      size="15"
                      style="margin-top: 0.8rem"
                      class="text-warning ml-1 cursor-pointer"
                      @click="selectItemToEdit(data.item)"
                    />
                    <tabler-icon
                      v-if="selectedRow.id === data.item.id"
                      icon="CheckIcon"
                      size="20"
                      style="margin-top: 0.5rem"
                      class="text-success ml-1 cursor-pointer"
                      @click="saveCommission(data.item)"
                    />
                    <tabler-icon
                      v-if="selectedRow.id === data.item.id"
                      icon="XIcon"
                      size="20"
                      style="margin-top: 0.5rem"
                      class="text-danger ml-1 cursor-pointer"
                      @click="selectedRow = []"
                    />
                  </div>
                </div>
                <span
                  v-if="errors[0]"
                  class="text-danger"
                >{{ errors[0] }}</span>
              </validation-provider>
            </validation-observer>
          </template>
          <template
            #cell(actions)="data"
          >
            <div class="d-flex justify-content-center">
              <feather-icon
                icon="ListIcon"
                size="15"
                style="margin-top: 0.5rem"
                class="text-primary ml-1 cursor-pointer"
                @click="openListTrackingCedCommissions(data.item, data.item.type_comission_number, 2)"
              />
            </div>
          </template>
        </b-table>
      </template>
    </filter-slot>
    <list-tracking-ced-commissions
      v-if="openListTrackingCedCommissionsOn"
      :id-commission="idToSend"
      :type-commission="typeCommission"
      :type-table="typeTable"
      @canceledChange="reloadTable"
      @close="closeListTrackingCedCommission"
    />
  </div>
</template>

<script>
import ManageCommissionsService from '@/views/crm/views/manage-commissions/manage-commissions.service'
import CeSetCommissionsService
from '@/views/ce-digital/sub-modules/settings/views/commissions/service/ce-set-commissions.service'
import { mapGetters } from 'vuex'
import ListTrackingCedCommissions from '@/views/ce-digital/sub-modules/settings/views/commissions/components/modals/ListTrackingCedCommissions.vue'

export default {
  name: 'ContentCommissions',
  components: {
    ListTrackingCedCommissions,
  },
  data() {
    return {
      fields: [
        {
          key: 'description',
          sortable: false,
          label: 'Type',
          visible: true,
        },
        {
          key: 'value',
          sortable: false,
          label: 'Value',
          visible: true,
        },
        {
          key: 'actions',
          sortable: false,
          label: 'Actions',
          visible: true,
          thStyle: {
            textAlign: 'center',
          },
        },
      ],
      itemsCommissions: [],
      totalRows: 1,
      paginate: {
        perPage: 10,
        currentPage: 1,
      },
      startPage: 0,
      toPage: 0,
      isBusy: false,
      sortBy: 'created_at',
      sortDesc: true,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      vMoney: {
        decimal: '.',
        thousand: ',',
        precision: 2,
        min: 0.0,
        maxlength: 10,
      },
      selectedRow: {},
      currentPercentage: '',
      openListTrackingCedCommissionsOn: false,
      idToSend: 0,
      typeCommission: 0,
      typeTable: 0,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  methods: {
    async getRolesCommissionsByProgram(ctx) {
      const data = await ManageCommissionsService.getRolesCommissionsByProgram({
        text: this.filterPrincipal.model,
        moduleId: this.$route.meta.moduleOfTab,
        perPage: this.paginate.perPage,
        page: this.paginate.currentPage,
        orderBy: ctx.sortBy ? ctx.sortBy : 'created_at',
        order: ctx.sortDesc ? 'desc' : 'asc',
      })
      this.itemsCommissions = data.data.data
      this.startPage = data.data.from
      this.paginate.currentPage = data.data.current_page
      this.paginate.perPage = data.data.per_page
      this.totalRows = data.data.total
      this.toPage = data.data.to
      return this.itemsCommissions || []
    },
    async saveCommission(commissionData, type = 2) {
      try {
        if (commissionData.value !== this.currentPercentage) {
          const result = await this.$refs.form.validate()
          if (result) {
            const response = await this.showConfirmSwal()
            if (response.isConfirmed) {
              const data = await CeSetCommissionsService.saveChangeCommission({
                new_value: commissionData.value,
                old_value: this.currentPercentage,
                type_data_commission: commissionData.type_comission_number,
                type_commission: commissionData.id,
                type_commission_description: commissionData.description,
                created_by: this.currentUser.user_id,
                status: 1, // Pending
                user_name: `${this.currentUser.first_name} ${this.currentUser.last_name}`,
                type,
              })
              if (data.status === 200) {
                this.showGenericSuccessSwal({})
                this.selectedRow = []
                this.$refs.refRolesCommissionsTable.refresh()
              }
            }
          }
        } else {
          this.showWarningSwal('Invalid Change', 'The entered value is the same as the old one')
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async selectItemToEdit(item) {
      this.currentPercentage = item.value
      this.selectedRow = item
    },
    openListTrackingCedCommissions(item, typeCommission, type) {
      this.idToSend = item.id + item.cc_types
      this.typeCommission = typeCommission
      this.typeTable = type
      this.openListTrackingCedCommissionsOn = true
    },
    closeListTrackingCedCommission() {
      this.openListTrackingCedCommissionsOn = false
    },
    reloadTable() {
      this.$refs.refRolesCommissionsTable.refresh()
    },
  },
}
</script>

<style scoped>

</style>
